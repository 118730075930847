import React from 'react';
import BlogPostCardNew from '../molecules/cards/BlogPostCard';
import { FlexGridCell } from '@sentry/static-shared/components/FlexGrid';
import NumericPaginator from './NumericPaginator';
import SyntaxBanner from '@sentry/static-shared/components/molecules/SyntaxBanner';
import BlogGridLayer from '../atoms/BlogGridLayer';
import BlogFlexGrid from '../atoms/BlogFlexGrid';
import BlogPostGridTabs from '../molecules/BlogPostGridTabs';

const HomePageGrid = ({ posts, pageContext, tabData }) => {

  const featuredImageHeight = 243;
  const defaultImageHeight = 163;

  const firstBlogs = posts
    ?.slice(0, 2)
    ?.map(post =>
      ({
        ...post,
        imageHeight: featuredImageHeight,
        isFeatured: true
      })
    );

  const nextBlogs = posts
    ?.slice(2, 5)
    ?.map(post =>
      ({
        ...post,
        imageHeight: defaultImageHeight
      })
    );

  const remainingBlogs = posts
    ?.slice(5, 14)
    ?.map(post =>
      ({
        ...post,
        imageHeight: defaultImageHeight
      })
    );

  return (
    <BlogGridLayer>
      <BlogFlexGrid>
        {firstBlogs.map(post => (
          <FlexGridCell md={6}>
            <BlogPostCardNew {...post} />
          </FlexGridCell>
        ))}
      </BlogFlexGrid>
      <BlogFlexGrid>
        {nextBlogs.map(post => (
          <FlexGridCell md={4}>
            <BlogPostCardNew {...post} />
          </FlexGridCell>
        ))}
      </BlogFlexGrid>
      <BlogFlexGrid>
        <FlexGridCell>
          <BlogPostGridTabs {...tabData}/>
        </FlexGridCell>
      </BlogFlexGrid>
      <BlogFlexGrid>
        {remainingBlogs.map(post => (
          <FlexGridCell md={4}>
            <BlogPostCardNew {...post} />
          </FlexGridCell>
        ))}
      </BlogFlexGrid>
      <NumericPaginator pageContext={pageContext} />
      <SyntaxBanner/>
    </BlogGridLayer>
  );
};

export default HomePageGrid;