import React from 'react';
import Layer from '@sentry/static-shared/components/layer-next';
import { richBlack } from '@sentry/static-shared/utils/css/colors';
import styled from '@emotion/styled';

const BlogGridLayer = ({ children }) => (
  <StyledLayer
    lightType
    backgroundColor={richBlack}
  >
    {children}
  </StyledLayer>
);

export default BlogGridLayer;

const StyledLayer = styled(Layer)`
  div.container {
    padding-top: 0;
  }
`;
