import React from 'react';
import Button from '@sentry/static-shared/components/Button';
import { mqMin } from '@sentry/static-shared/utils/css';
import styled from '@emotion/styled';

const BlogPostGridTabs = ({ tabHeader, tabs }) => (
  <TabsWrapper>
    <h3>{tabHeader}</h3>
    {tabs && tabs.map(({ link, text }) => (
      <Button
        to={link}
        redesign
        variant='secondary-dark'
        target='_blank'
      >
        {text}
      </Button>
    ))}
  </TabsWrapper>
);

export default BlogPostGridTabs;

const TabsWrapper = styled.div`
  ${mqMin('lg')} {
    margin-bottom: 3.75rem;
    margin-top: 3.75rem;
    flex-wrap: nowrap;
  }
  flex-wrap: wrap;
  display: flex;
  gap: 1rem;
  h3 {
    width: 100%;
    ${mqMin('lg')}{
      width: auto;
    }
  }
  a {
    height: 2rem;
    padding: 0.5rem 0.75rem;
    font-size: 13px;
    width: auto;
    span {
      line-height: 1;
    }
  }
`;
