import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { graphql } from 'gatsby';
import HeroLayer from '../components/organisms/HeroLayer';
import { useLocation } from '@reach/router';
import { canonicalURL } from '@sentry/static-shared/utils/CanonicalLink';
import HomePageGrid from '../components/organisms/HomePageGrid';
import excerptFromNode from '@sentry/static-shared/utils/excerpt-from-node';
import defaultMetaImage from '../assets/meta/default.png';
import BlogPostGrid from '../components/organisms/BlogPostGrid';

const tabData = {
  tabs: [
    {
      link: 'https://sentry.engineering',
      text: 'engineering blog'
    },
    {
      link: 'https://sentry.io/changelog',
      text: 'changelog'
    },
    {
      link: 'https://sentry.io/customers',
      text: 'customer stories'
    },
    {
      link: 'https://syntax.fm',
      text: 'syntax episodes'
    },
    {
      link: 'https://about.codecov.io/resources',
      text: 'codecov'
    }
  ],
  tabHeader: 'More Sentry content'
};

const IndexPage = ({
   data: {
     allContentfulBlogPost,
     allContentfulBlogCategory,
     contentfulBlogGeneralSettings,
     contentfulPage: blogData,
     site: {
       siteMetadata: { siteUrl },
     },
   },
   pageContext
 }) => {
  const location = useLocation();
  const canonicalLink = canonicalURL('www', location.pathname);
  const {
    blogHomepageTitle,
    blogHomepageMetaImage,
    blogHomepageMetaDescription
  } = contentfulBlogGeneralSettings;
  const isHomePage = !!pageContext?.first;
  const defaultAvatars = blogData.dataModules.find(
    x => x.slug === 'fallback-avatars'
  ).assets;

  const formatBlog = (post) => ({
    ...post,
    excerpt: excerptFromNode(post),
    metaImage: post?.metaImage?.file?.url || defaultMetaImage,
    cta: {
      link: post?.fields?.url,
      text: 'Read on'
    },
    tag: post?.category?.title,
    date: post?.publishDate,
    defaultAvatars
  });

  const posts = allContentfulBlogPost.edges.map(x => formatBlog(x.node));

  const BlogPostGridComponent = isHomePage ? HomePageGrid : BlogPostGrid;

  return (
    <Layout
      mode="dark"
    >
      <SEO
        title={blogHomepageTitle}
        description={blogHomepageMetaDescription}
        image={blogHomepageMetaImage.gatsbyImageData}
        canonical={canonicalLink}
      />
      <HeroLayer title={blogHomepageTitle} />
      <BlogPostGridComponent {...{ posts, pageContext, blogData, tabData }} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  fragment Image on ContentfulAsset {
    title
    description
    file {
      url
      contentType
      details {
        image {
          width
          height
        }
      }
    }
  }
  

  query blogListQuery($posts: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    # Global Blog Data
    contentfulPage(contentful_id: { eq: "6PaNQpeuz5DLUwC3KP09R5" }) {
      dataModules {
        slug
        assets {
          ...Image
          gatsbyImageData(width: 32)
        }
      }
    }
    allContentfulBlogCategory(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          title
          slug
        }
      }
    }

    allContentfulBlogPost(
      filter: { slug: { in: $posts } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          ...BlogPost
        }
      }
    }
    contentfulBlogGeneralSettings {
      blogHomepageTitle
      blogHomepageMetaDescription
      blogHomepageMetaImage {
        ...Image
      }
    }
  }
`;