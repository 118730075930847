import React from 'react';
import BlogPostCardNew from '../molecules/cards/BlogPostCard';
import { FlexGridCell } from '@sentry/static-shared/components/FlexGrid';
import NumericPaginator from './NumericPaginator';
import SyntaxBanner from '@sentry/static-shared/components/molecules/SyntaxBanner';
import BlogGridLayer from '../atoms/BlogGridLayer';
import BlogFlexGrid from '../atoms/BlogFlexGrid';
import BlogPostGridTabs from '../molecules/BlogPostGridTabs';

const BlogPostGrid = ({ posts, pageContext, tabData }) => {

  const defaultImageHeight = 163;

  posts = posts.map(post => ({ ...post, imageHeight: defaultImageHeight }));

  const firstBlogs = posts?.slice(0, 6);
  const remainingBlogs = posts?.slice(6, 15);

  return (
    <BlogGridLayer>
      <BlogFlexGrid>
        {firstBlogs.map(post => (
          <FlexGridCell md={4}>
            <BlogPostCardNew {...post} />
          </FlexGridCell>
        ))}
      </BlogFlexGrid>
      <BlogFlexGrid>
        <FlexGridCell>
          <BlogPostGridTabs {...tabData} />
        </FlexGridCell>
      </BlogFlexGrid>
      <BlogFlexGrid>
        {remainingBlogs.map(post => (
          <FlexGridCell md={4}>
            <BlogPostCardNew {...post} />
          </FlexGridCell>
        ))}
      </BlogFlexGrid>
      <NumericPaginator pageContext={pageContext} />
      <SyntaxBanner/>
    </BlogGridLayer>
  );
};

export default BlogPostGrid;