import React from 'react';
import FlexGrid from '@sentry/static-shared/components/FlexGrid';
import styled from '@emotion/styled';

const BlogFlexGrid = ({ children }) => (
  <StyledFlexGrid>
    {children}
  </StyledFlexGrid>
);

export default BlogFlexGrid;

const StyledFlexGrid = styled(FlexGrid)`
  > div {
    padding: 1.5rem 1rem;
  }
`;